<template>
  <div class="public">
    <div class="container HomeAcceptance">
      <!-- 四色码 -->

      <div class="idiot-one">
        <div class="idiot-child-top">
          <div class="idiot-child-top-url"></div>
          <div class="idiot-child-top-box">
            <div
              class="idiot-child-top-child"
              v-for="(item, i) in newforCodeData"
              :key="i"
            >
              {{ item.name
              }}<span class="idiot-child-top-child-span">{{ item.com }}</span>
            </div>
          </div>
        </div>
        <div class="box-idiot">
          <div class="box" v-for="(item, i) in newforCodeData" :key="i">
            <div class="box-one">
              <span>较昨日：</span>
              <span>{{ item.yesterdayNum }}</span>
              <span
                ><i
                  :class="
                    item.yesterdayNum > 0
                      ? 'el-icon-top t'
                      : item.yesterdayNum < 0
                      ? 'el-icon-bottom b'
                      : ''
                  "
                ></i
              ></span>
            </div>
            <div class="box-recent">{{item.yesterdayPercent}}<span>%</span></div>
            <div class="box-one">
              <span>较上周：</span>
              <span>{{ item.weekNum }}</span>
              <span
                ><i
                  :class="
                    item.weekNum > 0
                      ? 'el-icon-top t'
                      : item.weekNum < 0
                      ? 'el-icon-bottom b'
                      : ''
                  "
                ></i
              ></span>
            </div>
            <div class="box-recent">{{item.weekPercent}}<span>%</span></div>
            <div class="box-one">
              <span>较上月：</span>
              <span>{{ item.monthNum }}</span>
              <span
                ><i
                  :class="
                    item.monthNum > 0
                      ? 'el-icon-top t'
                      : item.monthNum < 0
                      ? 'el-icon-bottom b'
                      : ''
                  "
                ></i
              ></span>
            </div>
            <div class="box-recent">{{item.monthPercent}}<span>%</span></div>
            <div class="box-one">
              <span>较上年：</span>
              <span>{{ item.yearNum }}</span>
              <span
                ><i
                  :class="
                    item.yearNum > 0
                      ? 'el-icon-top t'
                      : item.yearNum < 0
                      ? 'el-icon-bottom b'
                      : ''
                  "
                ></i
              ></span>
            </div>
            <div class="box-recent">{{item.yearPercent}}<span>%</span></div>
          </div>
        </div>
      </div>

      <!-- 商户区域分布情况 中国地图 -->
      <div class="idiot-two echarts">
        <div class="echarts-box">
          <div class="echarts-tit">
            <span>投诉处理区域分布图</span>
          </div>
        </div>

        <div class="map-charts">
          <Baidumap
            v-if="regionType >= 3 && areaName"
            v-loading="showbmap"
            element-loading-text="加载中..."
            element-loading-spinner="el-icon-loading"
            element-loading-background="rgba(9,32,57, 0.1)"
            :regionName="regionName"
            :flag="flag"
            :cityName="cityName"
            :areaName="areaName"
            :data="baidumapOptions"
          />
          <Pmap
            v-else
            v-loading="showmap"
            element-loading-text="加载中..."
            element-loading-spinner="el-icon-loading"
            element-loading-background="rgba(9,32,57, 0.1)"
            :regionName="regionName"
            :cityName="cityName"
            :flag="flag"
            :mapOptions="mapOptions"
          ></Pmap>
        </div>
      </div>
      <!-- 投诉受理新增变化趋势 折线图图 -->
      <div class="idiot-two echarts">
        <div class="echarts-box">
          <div class="echarts-tit">
            <span>投诉处理新增变化趋势</span>
          </div>
          <!-- <el-radio-group v-model="tabPosition1" class="tab-select-btn">
            <el-radio-button label="1">投诉</el-radio-button>
            <el-radio-button label="2">处罚</el-radio-button>
          </el-radio-group> -->
          <div class="echarts-but">
            <el-radio-group v-model="radio1">
              <el-radio-button
                v-for="(item, ind) in radioData1"
                :label="item.value"
                :key="ind"
                >{{ item.name }}</el-radio-button
              >
            </el-radio-group>
          </div>
        </div>
        <div class="charts">
          <LineChart
            :chartData="lineChartData1"
            v-loading="lineChart1"
            element-loading-text="加载中..."
            element-loading-spinner="el-icon-loading"
            :legendData="legendData1"
            :units="unitData"
            :yAxis="['']"
            :yAxisIndex="['0']"
            :type="radio1"
          ></LineChart>
        </div>
      </div>
      <!-- 投诉受理累计变化趋势 折线图图 -->
      <div class="idiot-two echarts">
        <div class="echarts-box">
          <div class="echarts-tit">
            <span>投诉处理累计变化趋势 </span>
          </div>
          <!-- <el-radio-group v-model="tabPosition2" class="tab-select-btn">
            <el-radio-button label="1">投诉</el-radio-button>
            <el-radio-button label="2">处罚</el-radio-button>
          </el-radio-group> -->
          <div class="echarts-but">
            <el-radio-group v-model="radio2">
              <el-radio-button
                v-for="(item, ind) in radioData1"
                :label="item.value"
                :key="ind"
                >{{ item.name }}</el-radio-button
              >
            </el-radio-group>
          </div>
        </div>
        <div class="charts">
          <LineChart
            :chartData="lineChartData2"
            v-loading="lineChart2"
            element-loading-text="加载中..."
            element-loading-spinner="el-icon-loading"
            :legendData="legendData2"
            :units="unitData2"
            :yAxis="['']"
            :yAxisIndex="['0']"
            :type="radio2"
          ></LineChart>
        </div>
      </div>
      <!-- 投诉受理按城市/地区统计 表格 -->
      <div class="idiot-two echarts">
        <div class="echarts-box">
          <div class="echarts-tit">
            <span>投诉处理区域分布数量统计</span>
          </div>
          <!-- <el-radio-group
            v-model="tabPosition3"
            class="tab-select-btn right-size"
          >
            <el-radio-button label="0">投诉</el-radio-button>
            <el-radio-button label="1">处罚</el-radio-button>
          </el-radio-group> -->
          <div class="h-selct">
            <el-select v-model="hairpinType1" placeholder="请选择" size="mini">
              <el-option label="全部" value=""></el-option>
              <el-option
                v-for="item in options"
                :key="item.haripinType"
                :label="item.name"
                :value="item.haripinType"
              ></el-option>
            </el-select>
          </div>
        </div>
        <div class="element-tab">
          <el-table
            :data="tableDataAll"
            v-loading="loading1"
            element-loading-text="加载中..."
            element-loading-spinner="el-icon-loading"
            style="width: 100%"
            :row-key="rowKey"
            lazy
            :load="loadNextNode1"
            :tree-props="{ children: 'children', hasChildren: 'hasChildren' }"
            @sort-change="changeSort"
          >
            <el-table-column
              prop="regionName"
              :key="0"
              label="地区"
              sortable
              width="290"
            >
            </el-table-column>
            <el-table-column
              prop="complainNum"
              :key="1"
              label="已投诉"
              sortable
            >
              <template slot-scope="scope">
                <span class="num-box">{{ scope.row.compaintNum }}</span
                ><i
                  :class="
                    scope.row.complainNumSign > 0
                      ? 'num-badge-blue'
                      : scope.row.complainNumSign < 0
                      ? 'num-badge-orag'
                      : 'none'
                  "
                  >{{
                    scope.row.complainNumSign > 0
                      ? "+" + scope.row.complainNumSign
                      : scope.row.complainNumSign < 0
                      ? "" + scope.row.complainNumSign
                      : ""
                  }}</i
                >
              </template>
            </el-table-column>
            <el-table-column prop="acceptNum" :key="2" label="待审核" sortable>
              <template slot-scope="scope">
                <span class="num-box">{{ scope.row.pendingNum }}</span
                ><i
                  :class="
                    scope.row.pendingNumSign > 0
                      ? 'num-badge-blue'
                      : scope.row.pendingNumSign < 0
                      ? 'num-badge-orag'
                      : 'none'
                  "
                  >{{
                    scope.row.pendingNumSign > 0
                      ? "+" + scope.row.pendingNumSign
                      : scope.row.pendingNumSign < 0
                      ? "" + scope.row.pendingNumSign
                      : ""
                  }}</i
                >
              </template>
            </el-table-column>
            <el-table-column
              prop="notAcceptNum"
              :key="3"
              label="待分配"
              sortable
            >
              <template slot-scope="scope">
                <span class="num-box">{{ scope.row.distributionNum }}</span
                ><i
                  :class="
                    scope.row.distributionNumSign > 0
                      ? 'num-badge-blue'
                      : scope.row.distributionNumSign < 0
                      ? 'num-badge-orag'
                      : 'none'
                  "
                  >{{
                    scope.row.distributionNumSign > 0
                      ? "+" + scope.row.distributionNumSign
                      : scope.row.distributionNumSign < 0
                      ? "" + scope.row.distributionNumSign
                      : ""
                  }}</i
                >
              </template>
            </el-table-column>
            <el-table-column
              prop="notAcceptNum"
              :key="4"
              label="处理中"
              sortable
            >
              <template slot-scope="scope">
                <span class="num-box">{{ scope.row.processingNum }}</span
                ><i
                  :class="
                    scope.row.processingNumSign > 0
                      ? 'num-badge-blue'
                      : scope.row.processingNumSign < 0
                      ? 'num-badge-orag'
                      : 'none'
                  "
                  >{{
                    scope.row.processingNumSign > 0
                      ? "+" + scope.row.processingNumSign
                      : scope.row.processingNumSign < 0
                      ? "" + scope.row.processingNumSign
                      : ""
                  }}</i
                >
              </template>
            </el-table-column>
            <el-table-column
              prop="notAcceptNum"
              :key="5"
              label="已处理"
              sortable
            >
              <template slot-scope="scope">
                <span class="num-box">{{ scope.row.dealNum }}</span
                ><i
                  :class="
                    scope.row.dealNumSign > 0
                      ? 'num-badge-blue'
                      : scope.row.dealNumSign < 0
                      ? 'num-badge-orag'
                      : 'none'
                  "
                  >{{
                    scope.row.dealNumSign > 0
                      ? "+" + scope.row.dealNumSign
                      : scope.row.dealNumSign < 0
                      ? "" + scope.row.dealNumSign
                      : ""
                  }}</i
                >
              </template>
            </el-table-column>
            <el-table-column
              prop="notAcceptNum"
              :key="6"
              label="已撤销"
              sortable
            >
              <template slot-scope="scope">
                <span class="num-box">{{ scope.row.cancelNum }}</span
                ><i
                  :class="
                    scope.row.cancelNumSign > 0
                      ? 'num-badge-blue'
                      : scope.row.cancelNumSign < 0
                      ? 'num-badge-orag'
                      : 'none'
                  "
                  >{{
                    scope.row.cancelNumSign > 0
                      ? "+" + scope.row.cancelNumSign
                      : scope.row.cancelNumSign < 0
                      ? "" + scope.row.cancelNumSign
                      : ""
                  }}</i
                >
              </template>
            </el-table-column>
          </el-table>
          <div class="page-btn" v-if="tableData1.length > 10">
            <el-button type="text" @click="showAll = !showAll"
              >{{ showAll ? "收起全部" : "展开全部"
              }}<i
                class="el-icon-d-arrow-left el-icon--right"
                :class="showAll ? 'rotate-top' : 'rotate-bottom'"
              ></i
            ></el-button>
          </div>
        </div>
      </div>
      <!-- 投诉受理按领域/行业统计 表格 -->
      <div class="idiot-two echarts">
        <div class="echarts-box">
          <div class="echarts-tit">
            <span>投诉受理领域/行业分布数量统计</span>
          </div>
          <!-- <el-radio-group
            v-model="tabPosition4"
            class="tab-select-btn right-size"
          >
            <el-radio-button label="0">投诉</el-radio-button>
            <el-radio-button label="1">处罚</el-radio-button>
          </el-radio-group> -->
          <div class="h-selct">
            <el-select v-model="hairpinType2" placeholder="请选择" size="mini">
              <el-option label="全部" value=""></el-option>
              <el-option
                v-for="item in options"
                :key="item.haripinType"
                :label="item.name"
                :value="item.haripinType"
              ></el-option>
            </el-select>
          </div>
        </div>
        <div class="element-tab">
          <el-table
            :data="tableDataAll2"
            v-loading="loading2"
            element-loading-text="加载中..."
            element-loading-spinner="el-icon-loading"
            style="width: 100%"
            row-key="industryId"
            lazy
            :load="loadNextNode2"
            :tree-props="{ children: 'children', hasChildren: 'hasChildren' }"
            @sort-change="changeSort2"
          >
            <el-table-column
              prop="industryName"
              :key="0"
              label="领域/行业"
              sortable
            >
            </el-table-column>
            <el-table-column
                prop="complainNum"
                :key="1"
                label="已投诉"
                sortable
            >
              <template slot-scope="scope">
                <span class="num-box">{{ scope.row.compaintNum }}</span
                ><i
                  :class="
                    scope.row.complainNumSign > 0
                      ? 'num-badge-blue'
                      : scope.row.complainNumSign < 0
                      ? 'num-badge-orag'
                      : 'none'
                  "
              >{{
                  scope.row.complainNumSign > 0
                      ? "+" + scope.row.complainNumSign
                      : scope.row.complainNumSign < 0
                      ? "" + scope.row.complainNumSign
                      : ""
                }}</i
              >
              </template>
            </el-table-column>
            <el-table-column prop="acceptNum" :key="2" label="待审核" sortable>
              <template slot-scope="scope">
                <span class="num-box">{{ scope.row.pendingNum }}</span
                ><i
                  :class="
                    scope.row.pendingNumSign > 0
                      ? 'num-badge-blue'
                      : scope.row.pendingNumSign < 0
                      ? 'num-badge-orag'
                      : 'none'
                  "
              >{{
                  scope.row.pendingNumSign > 0
                      ? "+" + scope.row.pendingNumSign
                      : scope.row.pendingNumSign < 0
                      ? "" + scope.row.pendingNumSign
                      : ""
                }}</i
              >
              </template>
            </el-table-column>
            <el-table-column
                prop="notAcceptNum"
                :key="3"
                label="待分配"
                sortable
            >
              <template slot-scope="scope">
                <span class="num-box">{{ scope.row.distributionNum }}</span
                ><i
                  :class="
                    scope.row.distributionNumSign > 0
                      ? 'num-badge-blue'
                      : scope.row.distributionNumSign < 0
                      ? 'num-badge-orag'
                      : 'none'
                  "
              >{{
                  scope.row.distributionNumSign > 0
                      ? "+" + scope.row.distributionNumSign
                      : scope.row.distributionNumSign < 0
                      ? "" + scope.row.distributionNumSign
                      : ""
                }}</i
              >
              </template>
            </el-table-column>
            <el-table-column
                prop="notAcceptNum"
                :key="4"
                label="处理中"
                sortable
            >
              <template slot-scope="scope">
                <span class="num-box">{{ scope.row.processingNum }}</span
                ><i
                  :class="
                    scope.row.processingNumSign > 0
                      ? 'num-badge-blue'
                      : scope.row.processingNumSign < 0
                      ? 'num-badge-orag'
                      : 'none'
                  "
              >{{
                  scope.row.processingNumSign > 0
                      ? "+" + scope.row.processingNumSign
                      : scope.row.processingNumSign < 0
                      ? "" + scope.row.processingNumSign
                      : ""
                }}</i
              >
              </template>
            </el-table-column>
            <el-table-column
                prop="notAcceptNum"
                :key="5"
                label="已处理"
                sortable
            >
              <template slot-scope="scope">
                <span class="num-box">{{ scope.row.dealNum }}</span
                ><i
                  :class="
                    scope.row.dealNumSign > 0
                      ? 'num-badge-blue'
                      : scope.row.dealNumSign < 0
                      ? 'num-badge-orag'
                      : 'none'
                  "
              >{{
                  scope.row.dealNumSign > 0
                      ? "+" + scope.row.dealNumSign
                      : scope.row.dealNumSign < 0
                      ? "" + scope.row.dealNumSign
                      : ""
                }}</i
              >
              </template>
            </el-table-column>
            <el-table-column
                prop="notAcceptNum"
                :key="6"
                label="已撤销"
                sortable
            >
              <template slot-scope="scope">
                <span class="num-box">{{ scope.row.cancelNum }}</span
                ><i
                  :class="
                    scope.row.cancelNumSign > 0
                      ? 'num-badge-blue'
                      : scope.row.cancelNumSign < 0
                      ? 'num-badge-orag'
                      : 'none'
                  "
              >{{
                  scope.row.cancelNumSign > 0
                      ? "+" + scope.row.cancelNumSign
                      : scope.row.cancelNumSign < 0
                      ? "" + scope.row.cancelNumSign
                      : ""
                }}</i
              >
              </template>
            </el-table-column>
          </el-table>
          <div class="page-btn" v-if="tableData2.length > 10">
            <el-button type="text" @click="showAll2 = !showAll2"
              >{{ showAll2 ? "收起全部" : "展开全部"
              }}<i
                class="el-icon-d-arrow-left el-icon--right"
                :class="showAll2 ? 'rotate-top' : 'rotate-bottom'"
              ></i
            ></el-button>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script type="text/javascript">
import Pmap from "@/components/charts/map"; //地图
import LineChart from "@/components/charts/line2"; // 折线图
import {
  complaintInfo,
  complaintSupervision,
  complaintAdd,
  complaintCumulative,
  complaintStatistics,
  complaintIndustryStatistics,
} from "@/api/homeacceptance";
import { cardType } from "@/api/common";
import Baidumap from "@/components/baidumap/index.vue";

export default {
  name: "HomeAcceptance",
  props: {
    regionName: [String],
    areaName: [String],
    cityName: [String],
    flag: [String, Number],
    regionId: [String, Number],
    regionType: [String, Number],
  },
  data() {
    return {
      showmap: true,
      showbmap: true,
      lineChart1: false,
      lineChart2: false,
      hairpinType1: "",
      hairpinType2: "",
      options: [],
      newforCodeData: [
        {
          name: "0",
          com: "件",
          yesterdayNum: 0,
          weekNum: 0,
          monthNum: 0,
          yearNum: 0,
          yesterdayPercent: 0,
          weekPercent: 0,
          monthPercent: 0,
          yearPercent: 0,
        },
        {
          name: "0",
          com: "件",
          yesterdayNum: 0,
          weekNum: 0,
          monthNum: 0,
          yearNum: 0,
          yesterdayPercent: 0,
          weekPercent: 0,
          monthPercent: 0,
          yearPercent: 0,
        },
        {
          name: "0",
          com: "件",
          yesterdayNum: 0,
          weekNum: 0,
          monthNum: 0,
          yearNum: 0,
          yesterdayPercent: 0,
          weekPercent: 0,
          monthPercent: 0,
          yearPercent: 0,
        },
        {
          name: "0",
          com: "件",
          yesterdayNum: 0,
          weekNum: 0,
          monthNum: 0,
          yearNum: 0,
          yesterdayPercent: 0,
          weekPercent: 0,
          monthPercent: 0,
          yearPercent: 0,
        },
        {
          name: "0",
          com: "件",
          yesterdayNum: 0,
          weekNum: 0,
          monthNum: 0,
          yearNum: 0,
          yesterdayPercent: 0,
          weekPercent: 0,
          monthPercent: 0,
          yearPercent: 0,
        },
        {
          name: "0",
          com: "件",
          yesterdayNum: 0,
          weekNum: 0,
          monthNum: 0,
          yearNum: 0,
          yesterdayPercent: 0,
          weekPercent: 0,
          monthPercent: 0,
          yearPercent: 0,
        },
      ],
      tabPosition1: 1,
      tabPosition2: 1,
      tabPosition3: 0,
      tabPosition4: 0,
      radio1: "1",
      radio2: "1",
      radioData1: [
        {
          name: "近一周",
          value: "1",
        },
        {
          name: "近一月",
          value: "2",
        },
        {
          name: "近半年",
          value: "3",
        },
        {
          name: "全部",
          value: "4",
        },
      ],
      legendData1: ["已投诉", "待审核", "待分配", "处理中", "已处理", "已撤销"],
      unitData: ["件", "件", "件", "件", "件", "件"],
      lineChartData1: {
        xAxisData: [],
        seriesData: [],
      },
      legendData2: ["已投诉", "待审核", "待分配", "处理中", "已处理", "已撤销"],
      unitData2: ["件", "件", "件", "件", "件", "件"],
      lineChartData2: {
        xAxisData: [],
        seriesData: [],
      },
      showAll: false,
      tableDataAll: [],
      tableData1: [],
      showAll2: false,
      tableDataAll2: [],
      loading1: false,
      loading2: false,
      tableData2: [],
      mapOptions: [],
      baidumapOptions: [],
    };
  },
  computed: {
    costRequest() {
      return {
        regionName: this.regionName,
        areaName: this.areaName,
        cityName: this.cityName,
        flag: this.flag,
        regionId: this.regionId,
        regionType: this.regionType,
      };
    },
  },
  watch: {
    costRequest: {
      handler(val, oldVal) {
        this.intData();
      },
      deep: true, //增加deep 观察对象的子对象变化
    },
    radio1: function (val) {
      this.complaintAdd();
    },
    radio2: function (val) {
      this.complaintCumulative();
    },
    hairpinType1: function (val) {
      this.complaintStatistics();
    },
    tabPosition3: function (val) {
      this.complaintStatistics();
    },
    hairpinType2: function (val) {
      this.complaintIndustryStatistics();
    },
    tabPosition4: function (val) {
      this.complaintIndustryStatistics();
    },
    showAll: function (val) {
      this.showAllData();
    },
    showAll2: function (val) {
      this.showAllData2();
    },
  },
  mounted() {
    if (sessionStorage.getItem("initParams") === "init") {
      this.intData();
    }
  },
  beforeDestroy() {
    window.sessionStorage.setItem("initParams", "init");
  },
  methods: {
    intData() {
      this.cardType();
      this.getMapOptionData();
      this.complaintSupervision();
      this.complaintAdd();
      this.complaintCumulative();
      this.complaintStatistics();
      this.complaintIndustryStatistics();
    },
    cardType() {
      cardType().then((res) => {
        this.options = res.data;
      });
    },
    getMapOptionData() {
      this.showbmap=true;
      this.showmap=true;
      complaintInfo({
        regionId: this.regionId,
        regionType: this.regionType,
        flag: this.flag,
      })
        .then((res) => {
          // console.log("商户区域分布情况:", res);
          //this.mapOptions = res.data;
          let data = [];
          res.data.forEach((item) => {
            data.push({
              name: item.regionName,
              // value: item.num,
              value: item.compaintNum,
              compaintNum: item.compaintNum,
              pendingNum: item.pendingNum,
              distributionNum: item.distributionNum,
              processingNum: item.processingNum,
              dealNum: item.dealNum,
              cancelNum: item.cancelNum,
            });
          });
          this.mapOptions = data;
           this.showmap = false;
          if (this.regionType >= 3 && this.areaName) {
            this.baidumapOptions = data;
             this.showbmap = false;
          }
        })
        .catch((err) => {
          console.log(err);
        });
    },
    complaintSupervision() {
      complaintSupervision({
        regionId: this.regionId,
        regionType: this.regionType,
        flag: this.flag,
      })
        .then((res) => {
          // console.log('投诉处罚情况统计:',res)
          this.newforCodeData = [
            {
              name: res.data.num,
              com: "件",
              yesterdayNum: res.data.num2yesterday,
              weekNum: res.data.num2lastWeek,
              monthNum: res.data.num2lastMonth,
              yearNum: res.data.num2lastYear,
              yesterdayPercent: res.data.num2yesterdayRate,
              weekPercent: res.data.num2lastWeekRate,
              monthPercent: res.data.num2lastMonthRate,
              yearPercent: res.data.num2lastYearRate,
            },
            {
              name: res.data.pendingNum,
              com: "件",
              yesterdayNum: res.data.pendingNum2yesterday,
              weekNum: res.data.pendingNum2lastWeek,
              monthNum: res.data.pendingNum2lastMonth,
              yearNum: res.data.pendingNum2lastYear,
              yesterdayPercent: res.data.pendingNum2yesterdayRate,
              weekPercent: res.data.pendingNum2lastWeekRate,
              monthPercent: res.data.pendingNum2lastMonthRate,
              yearPercent: res.data.pendingNum2lastYearRate,
            },
            {
              name: res.data.distributionNum,
              com: "件",
              yesterdayNum: res.data.distributionNum2yesterday,
              weekNum: res.data.distributionNum2lastWeek,
              monthNum: res.data.distributionNum2lastMonth,
              yearNum: res.data.distributionNum2lastYear,
              yesterdayPercent: res.data.distributionNum2yesterdayRate,
              weekPercent: res.data.distributionNum2lastWeekRate,
              monthPercent: res.data.distributionNum2lastMonthRate,
              yearPercent: res.data.distributionNum2lastYearRate,
            },
            {
              name: res.data.processingNum,
              com: "件",
              yesterdayNum: res.data.processingNum2yesterday,
              weekNum: res.data.processingNum2lastWeek,
              monthNum: res.data.processingNum2lastMonth,
              yearNum: res.data.processingNum2lastYear,
              yesterdayPercent: res.data.processingNum2yesterdayRate,
              weekPercent: res.data.processingNum2lastWeekRate,
              monthPercent: res.data.processingNum2lastMonthRate,
              yearPercent: res.data.processingNum2lastYearRate,
            },
            {
              name: res.data.dealNum,
              com: "件",
              yesterdayNum: res.data.dealNum2yesterday,
              weekNum: res.data.dealNum2lastWeek,
              monthNum: res.data.dealNum2lastMonth,
              yearNum: res.data.dealNum2lastYear,
              yesterdayPercent: res.data.dealNum2yesterdayRate,
              weekPercent: res.data.dealNum2lastWeekRate,
              monthPercent: res.data.dealNum2lastMonthRate,
              yearPercent: res.data.dealNum2lastYearRate,
            },
            {
              name: res.data.cancelNum,
              com: "件",
              yesterdayNum: res.data.cancelNum2yesterday,
              weekNum: res.data.cancelNum2lastWeek,
              monthNum: res.data.cancelNum2lastMonth,
              yearNum: res.data.cancelNum2lastYear,
              yesterdayPercent: res.data.cancelNum2yesterdayRate,
              weekPercent: res.data.cancelNum2lastWeekRate,
              monthPercent: res.data.cancelNum2lastMonthRate,
              yearPercent: res.data.cancelNum2lastYearRate,
            },
          ];
        })
        .catch((err) => {
          console.log(err);
        });
    },
    complaintAdd() {
      this.lineChart1 = true;
      complaintAdd({
        regionId: this.regionId,
        regionType: this.regionType,
        flag: this.flag,
        type: this.radio1,
      })
        .then((res) => {
          this.lineChart1 = false;
          //console.log('投诉受理新增变化趋势',res)
          if (res.data != null) {
            this.lineChartData1 = {
              xAxisData: res.data.x_axis,
              seriesData: [
                {
                  name: "已投诉",
                  data: res.data.compaintNum,
                },
                {
                  name: "待审核",
                  data: res.data.pendingNum,
                },
                {
                  name: "待分配",
                  data: res.data.distributionNum,
                },
                {
                  name: "处理中",
                  data: res.data.processingNum,
                },
                {
                  name: "已处理",
                  data: res.data.dealNum,
                },
                {
                  name: "已撤销",
                  data: res.data.cancelNum,
                },
              ],
            };

          } else {
            this.lineChartData1 = {
              xAxisData: [],
              seriesData: [],
            };
          }
        })
        .catch((err) => {
          console.log(err);
        });
    },
    complaintCumulative() {
      this.lineChart2 = true;
      complaintCumulative({
        regionId: this.regionId,
        regionType: this.regionType,
        flag: this.flag,
        type: this.radio2,
      })
        .then((res) => {
          this.lineChart2 = false;
          //console.log('投诉受理累计势',res)
          if (res.data != null) {
            this.lineChartData2 = {
              xAxisData: res.data.x_axis,
              seriesData: [
                {
                  name: "已投诉",
                  data: res.data.compaintNum,
                },
                {
                  name: "待审核",
                  data: res.data.pendingNum,
                },
                {
                  name: "待分配",
                  data: res.data.distributionNum,
                },
                {
                  name: "处理中",
                  data: res.data.processingNum,
                },
                {
                  name: "已处理",
                  data: res.data.dealNum,
                },
                {
                  name: "已撤销",
                  data: res.data.cancelNum,
                },
              ],
            };
          } else {
            this.lineChartData2 = {
              xAxisData: [],
              seriesData: [],
            };
          }
          // console.log(this.lineChartData2, 11111);
        })
        .catch((err) => {
          console.log(err);
        });
    },
    complaintStatistics() {
      this.loading1 = true;
      complaintStatistics({
        hairpinType: this.hairpinType1,
        regionId: this.regionId,
        regionType: this.regionType,
        flag: this.flag,
      }).then((res) => {
        // console.log("投诉受理按城市/地区统计", res.data,this.regionType);
        console.log("预付码按城市/地区统计-二级", res);
        // res.data.forEach(item => {
        //   item.hasChildren = true;
        // })
        this.loading1 = false;
        if (res.data != "") {
          this.tableDataAll = [];
          this.$nextTick(() => {
            this.tableData1 = res.data || [];
            this.tableDataAll = this.tableData1.slice(0, 10);
            this.showAllData();
          });
        } else {
          this.tableData1 = [];
          this.tableDataAll = [];
        }
      });
    },
    showAllData() {
      if (!this.showAll) {
        this.tableDataAll = this.tableData1.slice(0, 10);
      } else {
        this.tableDataAll = this.tableData1;
      }
    },
    //确保row-key不重复
    rowKey(row) {
      return row.regionName + row.regionId;
    },
    loadNextNode1(tree, treeNode, resolve) {
      //console.log(tree, treeNode, resolve)
      complaintStatistics({
        hairpinType: this.hairpinType1,
        regionId: tree.regionId,
        regionType: tree.regionType,
        flag: tree.regionType,
      }).then((res) => {
        resolve(res.data || []);
      });
    },
    complaintIndustryStatistics() {
      this.loading2 = true;
      complaintIndustryStatistics({
        hairpinType: this.hairpinType2,
        regionId: this.regionId,
        regionType: this.regionType,
        flag: this.flag,
      }).then((res) => {
        res.data.forEach((item) => {
          item.hasChildren = true;
        });
        this.loading2 = false;
        this.tableDataAll2 = [];
        this.$nextTick(() => {
          this.tableData2 = res.data || [];
          this.tableDataAll2 = this.tableData2.slice(0, 10);
          this.showAllData2();
        });
      });
    },
    showAllData2() {
      if (!this.showAll2) {
        this.tableDataAll2 = this.tableData2.slice(0, 10);
      } else {
        this.tableDataAll2 = this.tableData2;
      }
    },
    loadNextNode2(tree, treeNode, resolve) {
      //console.log(tree, treeNode, resolve)
      complaintIndustryStatistics({
        hairpinType: this.hairpinType2,
        industryId: tree.industryId,
        regionId: this.regionId,
        regionType: this.regionType,
        flag: this.flag,
      }).then((res) => {
        //console.log('投诉受理按领域/行业统计-二级',res)
        let data = null;
        if (res.data == "") {
          data = [];
        } else {
          data = res.data;
        }
        resolve(data);
      });
    },
    changeSort(column, prop, order) {
      this.tableDataAll = this.tableData1;
      this.showAll = true;
    },
    changeSort2(column, prop, order) {
      this.tableDataAll2 = this.tableData2;
      this.showAll2 = true;
    },
  },
  components: {
    Pmap,
    LineChart,
    Baidumap,
  },
};
</script>

<style lang="scss" scoped >
.HomeAcceptance {
  // 四色码
  .idiot-one {
    height: 370px;
    margin: 0 30px 30px 30px;
    border: 1px solid #17688d;
    background: linear-gradient(
      to bottom,
      rgba(43, 202, 255, 0.1),
      #0000,
      rgba(43, 202, 255, 0.1)
    );
    .idiot-child-top {
      width: 100%;
      height: 140px;
      .idiot-child-top-url {
        width: 1023px;
        height: 78px;
        background: url("../assets/imgs/liucheng.png") no-repeat;
        background-size: 100% 100%;
        margin: 15px 0 10px 57px;
      }
      .idiot-child-top-box {
        width: 1064px;
        height: 30px;
        font-size: 24px;
        display: flex;
        align-items: center;
        justify-content: space-between;
        margin-left: 40px;
        .idiot-child-top-child {
          width: 120px;
          height: 100%;
          background-image: -webkit-linear-gradient(bottom, #2285ff, #13caff);
          -webkit-background-clip: text;
          -webkit-text-fill-color: transparent;
          text-align: center;
          line-height: 30px;
          .idiot-child-top-child-span {
            font-size: 14px;
          }
        }
        // .idiot-child-top-child:nth-child(2) {
        //   margin-left: 76px;
        // }
        // .idiot-child-top-child:nth-child(3) {
        //   margin-left: 83px;
        // }
        // .idiot-child-top-child:nth-child(4) {
        //   margin-left: 83px;
        // }
        // .idiot-child-top-child:nth-child(5) {
        //   margin-left: 83px;
        // }
        // .idiot-child-top-child:nth-child(6) {
        //   margin-left: 83px;
        // }
      }
    }
    .box-idiot {
      display: flex;
      justify-content: space-around;
    }
    .box {
      width: 16.6%;
      text-align: center;
      border: 0;
      border-right: 1px solid #ddd;
      border-image: linear-gradient(
          #0000,
          #0000,
          #17688d,
          #17688d,
          #0000,
          #0000
        )
        30 30;

      .box-one {
        display: flex;
        justify-content: center;
        font-size: 14px;
        line-height: 25px;
        span:nth-child(1) {
          color: #9fa1a7;
        }
        span:nth-child(2) {
          color: #eee;
          width: 60px;
          text-align: left;
        }
        span:nth-child(3) {
          color: #eee;
          width: 10px;
          font-size: 16px;
          .t {
            color: #ffba00;
            font-weight: bold;
          }
          .b {
            color: #59d2f3;
            font-weight: bold;
          }
        }
      }
      .box-recent{
        text-align: left;
        padding-left: 87px;
        font-size: 12px;
        color: rgb(26,192,193);
        margin-bottom: 6px;
        font-weight: bold;
      }
    }
  }
  .pie-box {
    background: url("../assets/imgs/pie-bg2.png") no-repeat;
    background-size: 100%;
    margin-bottom: 0;
    .echarts-box {
      position: relative;
    }
  }
  .tab-select-btn {
    position: absolute;
    top: 6px;
    right: 0;
    &.right-size {
      right: 120px;
    }
  }
}
</style>
